<template>
<div class="new__table new__table_2 " v-loading="loadingData">
    <div class="scheduler">
        <div class="schedule">
            <div class="crm-schedule-box d-inline-flex w-100">
                <table class="table table-bordered nurseOffice_table crm-schedule-table table-bordered">
                    <thead>
                        <tr>
                            <th>{{ $t('message.physiotherapy') }}</th>
                            <th v-for="date in dateList" :key="date.val + '-datess'">
                                {{ date.title }}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="physiotherapy in list" :key="physiotherapy.id">
                            <td class="left_likarstovas">
                                <div class="modalCells">
                                    {{ physiotherapy.hospitalizationService ?  physiotherapy.hospitalizationService.name : '' }}
                                </div>
                                <div class="ta_button">
                                    <template v-if="hasEdit(physiotherapy)">
                                        <el-button v-if="lastHistory.is_ended != 0" disabled type="success" icon="el-icon-edit" v-can="'physiotherapies.update'" @click="edit(physiotherapy)">{{ $t('message.change') }}</el-button>
                                        <el-button v-else type="success" icon="el-icon-edit" v-can="'physiotherapies.update'" @click="edit(physiotherapy)">{{ $t('message.change') }}</el-button>
                                    </template>
                                    <template v-else>
                                        <el-button type="success" icon="el-icon-edit" v-can="'physiotherapies.update'" :disabled="true">{{ $t('message.change') }}</el-button>
                                    </template>
                                    <el-button v-if="lastHistory.is_ended != 0" disabled type="danger" icon="el-icon-delete" v-can="'physiotherapies.delete'" @click="destroyModel(physiotherapy)">{{ $t('message.delete') }}</el-button>
                                    <el-button v-else type="danger" icon="el-icon-delete" v-can="'physiotherapies.delete'" @click="destroyModel(physiotherapy)">{{ $t('message.delete') }}</el-button>
                                </div>
                            </td>
                            <td v-for="date in dateList" :key="date.val + '-dat  '" class="dataDay">
                                <template v-if="hasLek(date,physiotherapy)">
                                    <template v-if="physiotherapy.is_edit != 0">
                                        <template v-for="i in physiotherapy.count">
                                            <div v-if="lastHistory.is_ended != 0" class="modalCells is_edit" :class="classObject(date,physiotherapy,i)" :key="i + '-count'"></div>
                                            <div v-else class="modalCells is_edit" :class="classObject(date,physiotherapy,i)" :key="i + '-count'" @click="openDialog(date,physiotherapy,i)"></div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-for="i in physiotherapy.count">
                                            <div v-if="lastHistory.is_ended != 0" class="modalCells" :class="classObject(date,physiotherapy,i)" :key="i + '-count'"></div>
                                            <div v-else class="modalCells" :class="classObject(date,physiotherapy,i)" :key="i + '-count'" @click="openDialog(date,physiotherapy,i)"></div>
                                        </template>
                                    </template>

                                </template>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <el-dialog class="modal_lea xModal" :title="$t('message.info_treatment')" width="30%" :visible.sync="dialogTableVisible2" @closed="closeDialog()" @opened="$refs['AddPhy'].afterOpened()">
            <AddPhy :selected="selectedModel_2" ref="AddPhy" @c-close="closeDialog" />
        </el-dialog>
    </div>
    <el-drawer size="100%" :visible.sync="drawer.update.status" :ref="drawer.update.name" @opened="drawerOpened(drawer.update.component)" @closed="drawerClosedAny(drawer.update.component)">
        <crm-update :selected="selectedModel" :ref="drawer.update.component" :drawer-name="drawer.update.name"></crm-update>
    </el-drawer>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import AddPhy from "./components/AddPhy";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import CrmUpdate from "./components/updatePhy";
import { i18n } from '@/utils/i18n';

export default {
    mixins: [list, drawer],
    name: "physiotherapy",
    components: {
        AddPhy,
        CrmUpdate,
    },
    data() {
        return {
            selectedModel: {},
            loadingData: false,
            isLoadingItems: false,
            dialogTableVisible2: false,
            selectedModel_2: {},
            filterForm: {},
            drawer: {
                create: {
                    name: "create",
                    status: false,
                    component: 'componentDrawerCreate'
                },
                update: {
                    name: "update",
                    status: false,
                    component: 'componentDrawerUpdate'
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            indexList: 'physiotherapyIndeces/list',
            list: 'physiotherapies/list',
            dateList: 'physiotherapies/dateList',
            columns: "physiotherapies/columns",
            pagination: "physiotherapies/pagination",
            filter: "physiotherapies/filter",
            sort: "physiotherapies/sort",
            physiotherapies: 'physiotherapies/model',
            lastHistory: 'dailyTreatments/lastHistory',

        }),
        actions: function () {
            return ['edit', 'delete'];
        }
    },
    async mounted() {
        $(".new__table_2 .crm-schedule-table tbody").scroll(function (e) {
            //detect a scroll event on the tbody
            $(".new__table_2 .crm-schedule-table thead").css(
                "left",
                -$(".new__table_2  .crm-schedule-table tbody").scrollLeft()
            ); //fix the thead relative to the body scrolling
            $(".new__table_2 .crm-schedule-table thead th:nth-child(1)").css(
                "left",
                $(".new__table_2 .crm-schedule-table tbody").scrollLeft()
            ); //fix the first cell of the header
            $(".new__table_2 .crm-schedule-table tbody td:nth-child(1)").css(
                "left",
                $(".new__table_2 .crm-schedule-table tbody").scrollLeft()
            ); //fix the first column of tdbody
        });
    },
    methods: {
        ...mapActions({
            updateList: 'physiotherapies/index',
            updateSort: "physiotherapies/updateSort",
            updateFilter: "physiotherapies/updateFilter",
            updateColumn: "physiotherapies/updateColumn",
            showDate: 'physiotherapies/show',
            updatePagination: "physiotherapies/updatePagination",
            empty: 'physiotherapies/empty',
            delete: 'physiotherapies/destroy',
            refreshData: 'physiotherapies/refreshData',

        }),
        hasLek(date, dailyT) {
            if (dailyT.end_date) {
                let days = dailyT.end_date;
                if (_.isArray(days) && days.includes(date.val)) {
                    return true;
                }
            }
        },
        hasEdit(dt) {
            if (dt.physiotherapyIndeces) {
                let indeces = dt.physiotherapyIndeces;
                if (_.isArray(indeces)) {
                    let item = _.find(indeces, function (o) {
                        return (o.index == 'defect' || o.index == 'refect')
                    });
                    if (item) {
                        return item;
                    }
                }
            }
        },
        drawerClosedAny(ref) {
            this.$refs[ref].closed();
            if (this.reloadList === true) {
                this.fetchData();
                this.fetchDataAny();
                this.afterFetchData();
            }
        },
        fetchData() {

        },
        fetchDataAny() {
            this.loadingData = true;
            this.updateList({
                    hospital_patient_id: this.getId,
                    id: this.lastHistory.id
                })
                .then(res => {
                    this.loadingData = false;
                }).catch(err => {
                    this.loadingData = false;
                    this.$alert(err)
                });
        },
        classObject: function (date, dt, i) {
            let a = this.checkIndex(date, dt, i);
            return [{
                'on_lics': (a == 'plus' || a == 'continue'),
                'od_lics': (a == 'minus'),
                'off_lics': (a == 'defect'),
                'off_user': (a == 'reject')
            }, 'height' + dt.count];
        },
        refresh() {
            this.refreshData()
                .then(res => {
                    this.filterForm = JSON.parse(JSON.stringify(this.filter));
                })
                .catch(err => {

                })
        },
        openDialog(date, dt, time) {
            if (dt.physiotherapyIndeces) {
                let days = dt.physiotherapyIndeces;
                if (_.isArray(days)) {
                    let item = _.find(days, function (o) {
                        return (o.day == date.val && o.time == time)
                    });
                    if (item) {
                        this.dialogTableVisible2 = true;
                        this.selectedModel_2 = item;
                    }
                }
            }
        },
        closeDialog() {
            this.dialogTableVisible2 = false
            this.fetchDataAny()
        },
        checkIndex(date, dt, i) {
            if (dt.physiotherapyIndeces) {
                let indeces = dt.physiotherapyIndeces;
                if (_.isArray(indeces)) {
                    let item = _.find(indeces, function (o) {
                        return (o.day == date.val && o.time == i)
                    });
                    return item ? item.index : 'minus';
                }
            }
        },
        edit(model) {
            this.selectedModel = model;
            this.drawer.update.status = true;
        },
        destroyModel(model) {
            this.$confirm(
                     i18n.t('message.do_you_want'),
                     i18n.t('message.warning'), {
                        confirmButtonText: i18n.t('message.yes'),
                        cancelButtonText: i18n.t('message.cancel'),
                        type: "warning"
                    }
                )
                .then(() => {
                    this.delete(model.id)
                        .then(res => {
                            this.$alert(res);
                            this.fetchData();
                            this.fetchDataAny();
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: i18n.t('message.cancel')
                    });
                });
        },
        emptyModel() {
            this.empty()
        },
    }
};
</script>

<style lang="css">
.new__table .scheduler table.crm-schedule-table>tbody tr td:nth-child(1) {
    flex-direction: column;
}

.modalCells {
    flex: 1;
}

.is_edit {
    background: #f56c6c8a !important;
}

.ta_button {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 3px;
}

.ta_button .el-button {
    width: 48%;
    margin: 0px;
}

.new__table.new__table_2 tbody::-webkit-scrollbar {
    width: 10px;
    /* width of the entire scrollbar */
}

.new__table.new__table_2 tbody::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* color of the tracking area */
}

.new__table.new__table_2 tbody::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid #f1f1f1;
    /* creates padding around scroll thumb */
}

body::-webkit-scrollbar {
    width: 15px;
    /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
    background: #fff;
    /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid #fff;
    /* creates padding around scroll thumb */
}
</style>
